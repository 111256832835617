import axios from 'axios';
import dotenv from 'dotenv';
import moment from 'moment-timezone';
import store from 'store';

dotenv.config();

const API_BASE_URL = process.env.GATSBY_API_URL;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;
const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const EVENT_URL = process.env.GATSBY_EVENT_URL!;

const extractAPIData = (res: any) => {
    if (res?.data) return res.data;
    else return res;
};

// Refresh token
const refreshToken = async ({ refreshToken }: { refreshToken: string }) => {
    const res = await axios.post(
        `${API_BASE_URL}/auth/refresh-token`,
        { refreshToken },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as APIRequester.RefreshTokenResponse;
};

const extractAPIError = (res: any) => {
    if (res?.response?.data) return res.response.data;
    else return res;
};

// Token refresh interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const errorCode = error.response?.data?.code;
        if (errorCode === 'ACCESS_TOKEN_EXPIRED') {
            // Try refreshing token
            const refreshTokenValue = store.get('refreshToken');
            if (refreshTokenValue)
                return refreshToken({ refreshToken: refreshTokenValue }).then(data => {
                    const { accessToken } = data;
                    store.set('token', accessToken);
                    return axios.request({
                        ...error.config,
                        headers: { ...error.config.headers, Authorization: `Bearer ${accessToken}` },
                    });
                });
            else return Promise.reject(extractAPIError(error));
        } else return Promise.reject(extractAPIError(error));
    },
);

// Get event
const getEvent = async () => {
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}`, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as Events.Event;
};

// Fetch event modules
const getEventModules = async () => {
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules`, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as Modules.Module[];
};

// Get captcha compulsion
const getCaptchaCompulsion = async ({ moduleId }: { moduleId: string }) => {
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/captcha-compulsion`,
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as { enableCaptchaAtLogin: true; enableCaptchaAtRegistration: true };
};

// Login
const login = async ({
    eventAuthModule,
    emailId,
    password,
    captchaToken,
}: {
    eventAuthModule: Modules.Module<Modules.AuthModuleData>;
    emailId: string;
    password: string;
    captchaToken?: string;
}) => {
    const { _id: authModuleId } = eventAuthModule;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${authModuleId}/login`,
        { emailId, password, captchaToken },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as APIRequester.LoginResponse;
};

// Register
const register = async ({
    eventAuthModule,
    firstName,
    lastName,
    company,
    emailId,
    password,
    extras,
    profilePicture,
    paramsString,
    captchaToken,
}: {
    eventAuthModule: Modules.Module<Modules.AuthModuleData>;
    firstName: string;
    lastName: string;
    company: string;
    emailId: string;
    password: string;
    extras?: any[];
    profilePicture?: any;
    paramsString?: string;
    captchaToken?: string;
}) => {
    const { _id: authModuleId } = eventAuthModule;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${authModuleId}/register`,
        { firstName, lastName, company, emailId, password, extras, profilePicture, paramsString, captchaToken },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as APIRequester.LoginResponse;
};

// Forgot password
const forgotPassword = async ({
    eventAuthModule,
    emailId,
}: {
    eventAuthModule: Modules.Module<Modules.AuthModuleData>;
    emailId: string;
}) => {
    const { _id: authModuleId } = eventAuthModule;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${authModuleId}/forgot-password`,
        { emailId },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as boolean;
};

// Reset password
const resetPassword = async ({
    eventAuthModule,
    token,
    newPassword,
}: {
    eventAuthModule: Modules.Module<Modules.AuthModuleData>;
    token: string;
    newPassword: string;
}) => {
    const { _id: authModuleId } = eventAuthModule;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${authModuleId}/reset-password`,
        { token, newPassword },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as boolean;
};

// Verify email
const verifyEmail = async ({
    eventAuthModule,
    token,
}: {
    eventAuthModule: Modules.Module<Modules.AuthModuleData>;
    token: string;
}) => {
    const { _id: authModuleId } = eventAuthModule;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${authModuleId}/verify-email`,
        { token },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as boolean;
};

// Get booth
const getBooth = async ({ boothId }: { boothId: string }) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Booths.Booth;
};

// Get booths
const getAllBooths = async () => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths`, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as Booths.Booth[];
};

// Fetch booth modules
const getBoothModules = async ({ boothId }: { boothId: string }) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.Module[];
};

// Fetch booth modules
const getLiveStreamLink = async <T>({ boothId, moduleId }: { boothId: string; moduleId?: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/live-stream-link`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as { playbackUrl: string };
};

// Fetch booth modules
const getBootVideoCallRequests = async ({ boothId }: { boothId: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/video-call/requests/visitor`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoCallRequest[];
};

const acceptVideoCall = async ({
    boothId,
    moduleId,
    requestId,
}: {
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/accept/visitor`,
        {
            status,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const joinVideoCall = async ({
    boothId,
    moduleId,
    requestId,
}: {
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/join`,
        {
            status,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoCallRequest & { requester: Users.User; respondent: Users.User };
};

const rejectVideoCall = async ({
    boothId,
    moduleId,
    requestId,
}: {
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/reject/visitor`,
        {
            status,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const endVideoCall = async ({
    boothId,
    moduleId,
    requestId,
}: {
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/end/visitor`,
        {
            status,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const requestVideoCall = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/requests/visitor`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getScheduledRooms = async () => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/rooms/scheduled/visitor`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.VideoRoom[];
};

const getSchedulableRooms = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/rooms/schedulable`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoRoom[];
};

const bookSlotInSchedulableRoom = async ({
    boothId,
    moduleId,
    roomId,
}: {
    boothId: string;
    moduleId: string;
    roomId: string;
}) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/book-slot?tz=${moment.tz.guess()}`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoRoom[];
};

const getFiles = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/files`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.File[];
};

const getICalUrl = async ({
    boothId,
    moduleId,
    roomId,
    url,
}: {
    boothId: string;
    moduleId: string;
    roomId: string;
    url: string;
}) => {
    return `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/ical?url=${encodeURI(
        url,
    )}`;
};

const getChats = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/visitor-chats`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.Chat[];
};

const createChat = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/chats`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.Chat[];
};

const getUserExtraProfileFields = async () => {
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields`, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as Users.ExtraField[];
};

const createUserExtraProfileField = async (field: Users.ExtraField) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields`,
        field,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const updateUserExtraProfileField = async (fieldId: string, field: Users.ExtraField) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields/${fieldId}`,
        field,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteUserExtraProfileField = async (fieldId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields/${fieldId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getUserExtraProfileFieldFileUploadUrl = ({ fieldId }: { fieldId: string }) =>
    `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields/${fieldId}/files`;

const deleteUserExtraProfileFieldFileUpload = async (fieldId: string, fileId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/extra-user-profile-fields/${fieldId}/files/${fileId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getAuthModuleConfig = async (moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/auth-config`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.AuthModuleData;
};

const getProfilePictureUploadUrl = ({ moduleId }: { moduleId: string }) =>
    `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/register/profile-picture`;

const deleteProfilePicture = async (moduleId: string, fileId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/register/profile-picture/${fileId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getDownloadBasket = async () => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/download-basket`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as DownloadBasket.Basket;
};

const addItemToDownloadBasket = async ({
    boothId,
    moduleId,
    fileId,
}: {
    boothId: string;
    moduleId: string;
    fileId: string;
}) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/download-basket/items`,
        {
            booth: boothId,
            module: moduleId,
            file: fileId,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const removeItemFromDownloadBasket = async ({ itemId }: { itemId: string }) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/download-basket/items/${itemId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const logout = async () => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/logout`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as string;
};

const publicSignIn = async ({
    firstName,
    lastName,
    company,
    emailId,
    extras,
    profilePicture,
}: {
    firstName?: string;
    lastName?: string;
    company?: string;
    emailId?: string;
    extras?: any[];
    profilePicture?: any;
}) => {
    const user = store.get('user') as Users.User;
    const res = await axios.post(
        `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/public-sign-in`,
        JSON.parse(
            JSON.stringify({
                userId: user?._id,
                firstName,
                lastName,
                company,
                emailId,
                extras,
                profilePicture,
            }),
        ),
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as { token: string; user: Users.User; refreshToken: string };
};

const getLiveStreamQuestions = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/live-streaming-questions?status=all`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.LiveStreamQuestion[];
};

const askQuestion = async ({
    boothId,
    moduleId,
    question,
}: {
    boothId: string;
    moduleId: string;
    question: string;
}) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/live-streaming-questions`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { question },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getModuleVideos = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/videos`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.VideoModuleVideo[];
};

const submitExtFormResponse = async ({ moduleId, responses }: { moduleId: string; responses: any }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/ext/responses`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { responses },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoModuleVideo[];
};

const getSiemensFormQuestions = async ({ moduleId }: { moduleId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/modules/${moduleId}/siemens/questions`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.SiemensFormQuestion[];
};

const getProducts = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/products`;
    const token = store.get('token');
    const products = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(products) as Products.Product[];
};

const getBoothRedirectQuery = async ({ boothId }: { boothId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/booth-redirect-query`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as { panoramaName: string };
};

const getBoothSuggestions = async () => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booth-suggestions`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Booths.Booth[];
};

const getLiveStreamRecordings = async ({ boothId, moduleId }: { boothId: string; moduleId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/live-streaming/recordings`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.DailyCoRecordings[];
};

const generateS3DownloadUrl = (s3Url: string) => {
    const url = new URL(s3Url);
    const baseAddress = `${url.protocol}//${url.host}`;
    const path = s3Url.replace(baseAddress, '');
    const proxiedUrl = `${API_BASE_URL}/utilities/convert-to-download${path}`;
    return proxiedUrl;
};

const getProfile = async ({ userId }: { userId: string }) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/users/${userId}`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Users.User;
};

const saveProfile = async (_id: string, user: Users.User) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/users/${_id}`;
    const token = store.get('token');
    const res = await axios.put(url, user, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Users.User;
};

const notifyVisitorTriedChatting = async (boothId: string, moduleId: string, message: string) => {
    const url = `${API_BASE_URL}/clients/${CLIENT_ID}/events/${EVENT_ID}/booths/${boothId}/modules/${moduleId}/notify-tired-chatting`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { message },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Users.User;
};

export const apiRequester = {
    login,
    register,
    getEvent,
    getEventModules,
    forgotPassword,
    resetPassword,
    verifyEmail,
    getFiles,
    getBooth,
    getBoothModules,
    getLiveStreamLink,
    requestVideoCall,
    acceptVideoCall,
    joinVideoCall,
    rejectVideoCall,
    endVideoCall,
    getAuthModuleConfig,
    getBootVideoCallRequests,
    getChats,
    createChat,
    getUserExtraProfileFields,
    createUserExtraProfileField,
    updateUserExtraProfileField,
    deleteUserExtraProfileField,
    getUserExtraProfileFieldFileUploadUrl,
    deleteUserExtraProfileFieldFileUpload,
    getProfilePictureUploadUrl,
    deleteProfilePicture,
    getDownloadBasket,
    addItemToDownloadBasket,
    removeItemFromDownloadBasket,
    logout,
    publicSignIn,
    getScheduledRooms,
    getSchedulableRooms,
    bookSlotInSchedulableRoom,
    getICalUrl,
    getLiveStreamQuestions,
    askQuestion,
    getAllBooths,
    getModuleVideos,
    submitExtFormResponse,
    getSiemensFormQuestions,
    getProducts,
    getBoothRedirectQuery,
    getCaptchaCompulsion,
    getBoothSuggestions,
    getLiveStreamRecordings,
    generateS3DownloadUrl,
    getProfile,
    saveProfile,
    notifyVisitorTriedChatting,
};
