import Pubnub from 'pubnub';

export class SendUserActions {
    public static send = async ({
        pubnub,
        channel,
        action,
        boothId,
        moduleId,
        resourceId,
    }: {
        pubnub: Pubnub;
        channel: string;
        action: VisitorActions.ACTIONS;
        boothId?: string;
        moduleId?: string;
        resourceId?: string;
    }) => {
        console.log({
            channel: channel,
            message: {
                action,
                boothId,
                moduleId,
                resourceId,
            },
        });
        return pubnub.publish({
            channel: channel,
            message: {
                action,
                boothId,
                moduleId,
                resourceId,
            },
        });
    };

    public static enterBooth = async ({
        pubnub,
        channel,
        boothId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'enter-booth', boothId });

    public static exitBooth = async ({
        pubnub,
        channel,
        boothId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'exit-booth', boothId });

    public static login = async ({ pubnub, channel }: { pubnub: Pubnub; channel: string }) =>
        SendUserActions.send({ pubnub, channel, action: 'login' });

    public static logout = async ({ pubnub, channel }: { pubnub: Pubnub; channel: string }) =>
        SendUserActions.send({ pubnub, channel, action: 'logout' });

    public static downloadFile = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        fileId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        fileId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'download-file', boothId, moduleId, resourceId: fileId });

    public static requestVideoCall = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'request-video-call', boothId, moduleId });

    public static acceptVideoCallRequest = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        requestId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        requestId: string;
    }) =>
        SendUserActions.send({
            pubnub,
            channel,
            action: 'accept-video-call-request',
            boothId,
            moduleId,
            resourceId: requestId,
        });

    public static rejectVideoCallRequest = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        requestId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        requestId: string;
    }) =>
        SendUserActions.send({
            pubnub,
            channel,
            action: 'reject-video-call-request',
            boothId,
            moduleId,
            resourceId: requestId,
        });

    public static joinVideoRoom = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        requestId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        requestId: string;
    }) =>
        SendUserActions.send({ pubnub, channel, action: 'join-video-room', boothId, moduleId, resourceId: requestId });

    public static endVideoRoom = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        requestId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        requestId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'end-video-room', boothId, moduleId, resourceId: requestId });

    public static leaveVideoRoom = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        requestId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        requestId: string;
    }) =>
        SendUserActions.send({ pubnub, channel, action: 'leave-video-room', boothId, moduleId, resourceId: requestId });

    public static initiateChat = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
    }) => SendUserActions.send({ pubnub, channel, action: 'initiate-chat', boothId, moduleId });

    public static bookVideoCallSlot = async ({
        pubnub,
        channel,
        boothId,
        moduleId,
        roomId,
    }: {
        pubnub: Pubnub;
        channel: string;
        boothId: string;
        moduleId: string;
        roomId: string;
    }) =>
        SendUserActions.send({
            pubnub,
            channel,
            action: 'book-video-call-slot',
            boothId,
            moduleId,
            resourceId: roomId,
        });
}
